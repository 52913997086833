<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.35 202.15"
  >
    <path
      fill="#6596e2"
      d="M225.67,68.86A10.59,10.59,0,0,0,223,70.1c-2.29,2-4.46,4.1-6.82,6.29a100.79,100.79,0,0,0-37.86-41.53A99.38,99.38,0,0,0,124.41,20,98.13,98.13,0,0,0,70.83,36a101.05,101.05,0,0,0-45.56,99.21,86.19,86.19,0,0,1,16.29-9C39,85.69,66.16,49.49,104.61,39.67,147,28.84,184.34,52.73,198,79.36c-2.5-.78-4.84-1.58-7.23-2.2a6.67,6.67,0,0,0-2.34.28,6.88,6.88,0,0,0,.59,2.15q14,20,28,39.86a15.74,15.74,0,0,0,1.05,1.19l1.19-7.26c2.29-13.92,4.6-27.84,6.84-41.78A9.45,9.45,0,0,0,225.67,68.86Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M56.75,215.72a39.13,39.13,0,1,1,.84-78.26c21.56.2,38.6,17.88,38.42,39.87A39,39,0,0,1,56.75,215.72Zm-7.53-57.56a5.67,5.67,0,0,0,.06.9c1.51,7.67,3,15.34,4.58,23a2.89,2.89,0,0,0,3,2.44,3,3,0,0,0,3.12-2.6c.63-2.66,1.21-5.32,1.72-8,1-5.21,2.19-10.41,2.79-15.67a7.38,7.38,0,0,0-7.57-8A7.69,7.69,0,0,0,49.22,158.16Zm7.68,42.68a5.22,5.22,0,1,0-5.23-5.15A5.17,5.17,0,0,0,56.9,200.84Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M134,112.54h2.52q13.89,0,27.8,0c5.34,0,9.14,3.53,9.17,8.47s-3.75,8.6-9.21,8.61H126.19c-5.7,0-9.26-3.47-9.27-9.15q0-27.24,0-54.49c0-4.87,2.94-8.5,7.17-9.08a8.37,8.37,0,0,1,9.53,6,12.81,12.81,0,0,1,.35,3.51q0,21.82,0,43.67Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M100.45,201.54c3.87.81,8.09,1.84,12.36,2.57a75.35,75.35,0,0,0,22.49.39c5.1-.67,8.83,1.16,10.42,5.2,1.93,4.89-.89,10.63-6.08,11.35a114.39,114.39,0,0,1-16.54,1.07,95,95,0,0,1-30.53-5.43c-.41-.14-.81-.31-.88-.34Q96.06,209,100.45,201.54Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M200,167.73a7.94,7.94,0,0,1,7.1,4.18c1.65,2.85,1.9,6-.06,8.65a109.39,109.39,0,0,1-9.76,11.5c-3.14,3.19-8.53,2.72-11.62-.47a8.62,8.62,0,0,1-.24-11.7c2.6-3,5.16-6,7.74-9.08A8.52,8.52,0,0,1,200,167.73Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M215.52,134.52a8.36,8.36,0,0,1,8.21,10.28,120.89,120.89,0,0,1-4.23,13.3,8.34,8.34,0,0,1-10.83,4.67,8.6,8.6,0,0,1-5.09-10.68c1.21-3.86,2.44-7.7,3.65-11.55A8.48,8.48,0,0,1,215.52,134.52Z"
      transform="translate(-17.74 -19.97)"
    />
    <path
      :fill="fillColor"
      d="M172.42,192.1a8.8,8.8,0,0,1,8.25,6.34,8.14,8.14,0,0,1-3.49,9.44,126.74,126.74,0,0,1-12.81,6.44,8.24,8.24,0,0,1-10.7-4.54,8.51,8.51,0,0,1,4.05-11.09c3.61-1.82,7.18-3.7,10.82-5.44A24.83,24.83,0,0,1,172.42,192.1Z"
      transform="translate(-17.74 -19.97)"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'TimeoutIcon',
  computed: {
    fillColor(): string {
      return this.$vuetify.theme.dark ? '#DCDCDC' : '#21211F'
    },
  },
})
</script>
