import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import { VuetifyThemeItem, Theme } from 'vuetify/types/services/theme'
import CSVIcon from './vuetify-custom-icons/CSVIcon.vue'
import XLSIcon from './vuetify-custom-icons/XLSIcon.vue'
import PDFIcon from './vuetify-custom-icons/PDFIcon.vue'
import ImportIcon from './vuetify-custom-icons/ImportIcon.vue'
import TimeoutIcon from './vuetify-custom-icons/TimeoutIcon.vue'

Vue.use(Vuetify)

export type VuetifyTheme = Theme

export interface StrictVuetifyThemeVariant {
  primary: VuetifyThemeItem
  secondary: VuetifyThemeItem
  accent: VuetifyThemeItem
  info: VuetifyThemeItem
  warning: VuetifyThemeItem
  error: VuetifyThemeItem
  success: VuetifyThemeItem
  anchor?: string | number
}

export interface CustomThemeVariant {
  tertiary: VuetifyThemeItem
  cardPrimary: VuetifyThemeItem // Default card background
  level0: VuetifyThemeItem // Level 0 --> Background color
  level1: VuetifyThemeItem // Level 1 --> Slight contrast with background
  level1Control: VuetifyThemeItem // Icons on level 1
  toolbar1: VuetifyThemeItem // Top toolbar
  toolbar2: VuetifyThemeItem // Second level toolbar
  toolbar3: VuetifyThemeItem // Third level toolbar
  muted: VuetifyThemeItem
  ttPrimary: VuetifyThemeItem
  ttIndigo: VuetifyThemeItem
  ttWarning: VuetifyThemeItem
}

export interface TTGrey {
  'bg-grey': VuetifyThemeItem
  'bg-grey1': VuetifyThemeItem
  'bg-grey2': VuetifyThemeItem
  'bg-grey3': VuetifyThemeItem
  'bg-grey4': VuetifyThemeItem
  'bg-grey5': VuetifyThemeItem
}

export interface TTOrange {
  'bg-orange': VuetifyThemeItem
  'bg-orange1': VuetifyThemeItem
  'bg-orange2': VuetifyThemeItem
  'bg-text-orange1': VuetifyThemeItem
  'bg-text-orange2': VuetifyThemeItem
}

export interface TTGreen {
  'bg-green': VuetifyThemeItem
  'bg-green1': VuetifyThemeItem
  'bg-green2': VuetifyThemeItem
  'bg-text-green1': VuetifyThemeItem
  'bg-text-green2': VuetifyThemeItem
}

export interface TTRed {
  'bg-red': VuetifyThemeItem
  'bg-red1': VuetifyThemeItem
  'bg-red2': VuetifyThemeItem
  'bg-text-red1': VuetifyThemeItem
  'bg-text-red2': VuetifyThemeItem
}

export interface TTIndigo {
  'bg-indigo': VuetifyThemeItem
  'bg-indigo1': VuetifyThemeItem
  'bg-indigo2': VuetifyThemeItem
  'bg-text-indigo1': VuetifyThemeItem
  'bg-text-indigo2': VuetifyThemeItem
}

export type Chromatik = TTGrey & TTOrange & TTGreen & TTRed & TTIndigo

export type TrackTikThemeVariant = Partial<
  StrictVuetifyThemeVariant & CustomThemeVariant & Chromatik
>
export type TrackTikThemeKey = keyof TrackTikThemeVariant

const light: TrackTikThemeVariant = {
  primary: '#393F51', // Side menu
  secondary: '#2A2E3C', // Top toolbar
  tertiary: colors.indigo.base,
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  cardPrimary: '#f6fbfd',
  level0: colors.shades.white,
  level1: '#fafafa',
  level1Control: '#666',
  toolbar1: '#2A2E3C',
  toolbar2: colors.shades.white,
  toolbar3: '#f5f5f5',
  muted: colors.grey.lighten3,
  ttPrimary: '#4E69E9',
  ttIndigo: '#E9EAFF',
  ttWarning: '#f17300',
  'bg-grey': '#69788c',
  'bg-grey1': '#ffffff',
  'bg-grey2': '#f9fafa',
  'bg-grey3': '#f3f4f5',
  'bg-grey4': '#eceef0',
  'bg-grey5': '#e1e4e6',
  'bg-orange': '#f17300',
  'bg-orange1': '#ffefe1',
  'bg-orange2': '#f17300',
  'bg-green': '#0E882A',
  'bg-green1': '#DEF9E4',
  'bg-text-green1': '#0E8129',
  'bg-text-green2': '#0E882A',
  'bg-text-orange1': '#DB6A00',
  'bg-text-orange2': '#f17300',
  'bg-red': '#e61236',
  'bg-red1': '#FFE4E9',
  'bg-text-red1': '#D01131',
  'bg-text-red2': '#e61236',
  'bg-indigo': '#4e69e9',
  'bg-indigo1': '#E9EAFF',
  'bg-text-indigo1': '#405CE7',
  'bg-text-indigo2': '#4e69e9',
}

const dark: TrackTikThemeVariant = {
  primary: colors.shades.black,
  secondary: colors.shades.black,
  tertiary: colors.indigo.darken2,
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  cardPrimary: '#101010',
  level0: colors.shades.black,
  level1: '#101010',
  level1Control: '#202020',
  toolbar1: '#212020',
  toolbar2: colors.shades.black,
  toolbar3: '#121212',
  muted: colors.grey.darken4,
  ttPrimary: '#7285ED',
  ttIndigo: '#2D42A5',
  ttWarning: '#f17300',
  'bg-grey': '#69788c',
  'bg-grey1': '#222324',
  'bg-grey2': '#2a2e31',
  'bg-grey3': '#343a40',
  'bg-grey4': '#474f58',
  'bg-grey5': '#56626e',
  'bg-orange': '#f17300',
  'bg-orange1': '#b35b0b',
  'bg-orange2': '#ff8313',
  'bg-green': '#0E882A',
  'bg-green1': '#09671F',
  'bg-text-green1': '#ffffff',
  'bg-text-green2': '#159A34',
  'bg-text-orange1': '#ffffff',
  'bg-text-orange2': '#ff8313',
  'bg-red': '#e61236',
  'bg-red1': '#ab1731',
  'bg-text-red1': '#ffffff',
  'bg-text-red2': '#f01230',
  'bg-indigo': '#4e69e9',
  'bg-indigo1': '#2D42A5',
  'bg-text-indigo1': '#ffffff',
  'bg-text-indigo2': '#5875ff',
}

export default new Vuetify({
  theme: {
    themes: {
      light,
      dark,
    },
    dark: false,
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      csvIcon: {
        // name of our custom icon
        component: CSVIcon, // our custom component
      },
      xlsIcon: {
        component: XLSIcon,
      },
      pdfIcon: {
        component: PDFIcon,
      },
      importIcon: {
        component: ImportIcon,
      },
      timeoutIcon: {
        component: TimeoutIcon,
      },
    },
  },
  customProperties: true,
})
