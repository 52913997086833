<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="2" :fill="fillColor" />
    <path
      d="M10.6846 23.4658H8.87305V22.3232H10.6846C10.9645 22.3232 11.1924 22.2777 11.3682 22.1865C11.5439 22.0921 11.6725 21.9619 11.7539 21.7959C11.8353 21.6299 11.876 21.4427 11.876 21.2344C11.876 21.0228 11.8353 20.8258 11.7539 20.6436C11.6725 20.4613 11.5439 20.3148 11.3682 20.2041C11.1924 20.0934 10.9645 20.0381 10.6846 20.0381H9.38086V26H7.91602V18.8906H10.6846C11.2412 18.8906 11.7181 18.9915 12.1152 19.1934C12.5156 19.3919 12.8216 19.667 13.0332 20.0186C13.2448 20.3701 13.3506 20.7721 13.3506 21.2246C13.3506 21.6836 13.2448 22.0807 13.0332 22.416C12.8216 22.7513 12.5156 23.0101 12.1152 23.1924C11.7181 23.3747 11.2412 23.4658 10.6846 23.4658ZM16.5439 26H14.9961L15.0059 24.8574H16.5439C16.9281 24.8574 17.252 24.7712 17.5156 24.5986C17.7793 24.4229 17.9779 24.1673 18.1113 23.832C18.248 23.4967 18.3164 23.0915 18.3164 22.6162V22.2695C18.3164 21.9049 18.2773 21.5843 18.1992 21.3076C18.1243 21.0309 18.012 20.7982 17.8623 20.6094C17.7126 20.4206 17.5286 20.279 17.3105 20.1846C17.0924 20.0869 16.8418 20.0381 16.5586 20.0381H14.9668V18.8906H16.5586C17.0339 18.8906 17.4684 18.972 17.8623 19.1348C18.2594 19.2943 18.6029 19.5238 18.8926 19.8232C19.1823 20.1227 19.4053 20.4808 19.5615 20.8975C19.721 21.3109 19.8008 21.7715 19.8008 22.2793V22.6162C19.8008 23.1208 19.721 23.5814 19.5615 23.998C19.4053 24.4147 19.1823 24.7728 18.8926 25.0723C18.6061 25.3685 18.2627 25.598 17.8623 25.7607C17.4652 25.9202 17.0257 26 16.5439 26ZM15.8262 18.8906V26H14.3613V18.8906H15.8262ZM22.3301 18.8906V26H20.8652V18.8906H22.3301ZM25.1621 21.9277V23.0703H21.9297V21.9277H25.1621ZM25.5039 18.8906V20.0381H21.9297V18.8906H25.5039Z"
      fill="#DC3545"
    />
    <path
      d="M15.3367 3.47251V10.9192L12.0833 7.66585C11.8233 7.40585 11.3967 7.40585 11.1367 7.66585C10.8767 7.92585 10.8767 8.34585 11.1367 8.60585L15.53 12.9992C15.79 13.2592 16.21 13.2592 16.47 12.9992L20.8633 8.60585C21.1233 8.34585 21.1233 7.92585 20.8633 7.66585C20.7388 7.54101 20.5697 7.47086 20.3933 7.47086C20.217 7.47086 20.0479 7.54101 19.9233 7.66585L16.67 10.9192V3.47251C16.67 3.10585 16.37 2.80585 16.0033 2.80585C15.6367 2.80585 15.3367 3.10585 15.3367 3.47251Z"
      fill="#8A98A6"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'CSVIcon',
  computed: {
    fillColor(): string {
      return this.$vuetify.theme.dark ? '#21211F' : '#FAFAFA'
    },
  },
})
</script>
